import React, { useEffect, useState } from 'react';
import { CategoryInner, CategoryItem, CategorySlider } from './CategorySlider.styles';
import { useRouter } from 'next/router';
import { removeEmoticons } from '@/utils/emoticonsStatus';
import { useSSRSelector } from '@/redux/ssrStore';

const CategorySliderComp = (props) => {
  const { showCardButton = false } = props;
  const { categories, productTags } = useSSRSelector((state) => state.catalogReducer);
  const { theme } = useSSRSelector((state) => state.storeReducer.store);
  const [bgColor, setBgColor] = useState('');
  const router = useRouter();

  useEffect(() => {
    if (theme?.colors?.primary_color) {
      setBgColor(theme?.colors?.primary_color);
    }
  }, [theme?.colors?.primary_color]);

  useEffect(() => {
    if (router?.query?.cid && router?.query?.cid !== -1) {
      setTimeout(() => {
        document.getElementById(`category-${router.query.cid}`)?.scrollIntoView({
          inline: 'nearest',
        });
      }, 300);
    }
  }, [router.query.cid]);

  return (
    <CategorySlider>
      <CategoryInner>
        {/* <CategoryItem
          id={`category--1`}
          backgroundColor={bgColor}
          fontColor="#ffffff"
          showButton={showCardButton}
          selected={-1 === props.activeIndex}
          onClick={() => props.onItemSelection(-1)}
        >
          All
        </CategoryItem> */}
        {[...productTags, ...categories].map(
          (item, index) =>
            (item.hasOwnProperty('is_active') && item.is_active === 0) || (
              <CategoryItem
                id={`category-${item.id}`}
                backgroundColor={bgColor}
                fontColor="#ffffff"
                showButton={showCardButton}
                key={index}
                selected={index === props.activeIndex}
                onClick={() => props.onItemSelection(item.id)}
              >
                <p>
                  {(item.hasOwnProperty('is_custom_tag')
                    ? removeEmoticons(item.name)
                    : item.name) || 'Available Items'}
                </p>
              </CategoryItem>
            )
        )}
      </CategoryInner>
    </CategorySlider>
  );
};

export default CategorySliderComp;
