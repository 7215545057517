import styled from 'styled-components';

export const CategorySlider = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 2rem;
  position: sticky;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 5;
  box-shadow: 0px 10px 6px -8px #00000029;

  @media screen and (min-width: 767px) {
    top: 58px;
    max-height: 100px;
  }

  @media screen and (max-width: 766px) {
    top: 47px;
  }
`;

export const CategoryInner = styled.section`
  display: flex;
  margin: 1rem 1rem 0.6rem;
  padding: 0.7rem 0 !important;
  flex: 1;
  flex-flow: row nowrap;
  overflow-y: auto;
  scroll-behavior: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  & > ul {
    display: flex;
    list-style-type: none;
    padding-bottom: 10px;
  }

  & a {
    text-decoration: none;
  }

  &::-webkit-scrollbar {
    height: 1px;
  }

  &::-webkit-scrollbar-track {
    background: #cbcbcb;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 3px;
    background: #000000;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const CategoryItem = styled.span`
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  background: ${props =>
    props.selected ? props.backgroundColor || '#6E27FF' : 'transparent'};
  color: ${props =>
    props.selected ? props.fontColor || '#ffffff' : '#A0A0A0'};
  border-radius: ${props => props.roundness || '0px'};
  font-weight: 500;
  cursor: pointer;
  max-width: 320px;
  width: fit-content;
  word-break: keep-all;
  white-space: nowrap;

  & > .item-seperator {
    height: 100%;
    border-right: 1px dashed #cbcbcb;
    position: relative;
  }

  & > p {
    margin: 0;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 767px) {
    font-size: 18px;
    padding: 10px 20px;
  }

  @media screen and (max-width: 766px) {
    font-size: 12px;
    padding: 5px 7px;
  }
`;
