import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import CategorySliderComp from '../../CategorySlider';
import { AdWidgetContainer, Container, Heading } from './Hybrid.styles';
import { conditionalForMiddlebodyRender } from '@/utils/conditionalForMiddlebodyRender';
import DesktopDsAds from '../DesktopDsAds';
// import useIsComponentMounted from '@/utils/useIsComponentMounted';
import { SelectedCategoryItems } from './components';
import { useItemClick } from 'src/hooks/useItemClick';
import { useSSRSelector } from '@/redux/ssrStore';
import '../../../../components/SortingAndFiltersTab/SortingAndFiltersTab';
import SortingAndFiltersTab from '../../../../components/SortingAndFiltersTab/SortingAndFiltersTab';
import useTriggerInitialFilterOptions from '@/hooks/useTriggerInitialFilterOptions';
import FilterDrawerMiddleBody5 from './components/FilterDrawerMiddleBody5';
import useGetAnimationTranslation from './utils/useGetAnimationTranslation';
import SortAndFilteredItems from './components/SortAndFilteredItems/SortAndFilteredItems';

const HybridFiltered = (props) => {
  const router = useRouter();

  const [categorySelected, setCategorySelected] = useState(null);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  const [showFilterDrawer, setShowFilterDrawer] = useState(null);
  const filterDrawerWrapperRef = useRef();

  const { translationDistanceForAnimation, middleBodyContainerRef } =
    useGetAnimationTranslation(showFilterDrawer);

  const [onItemClick] = useItemClick();

  const {
    catalogData: { categories, productTags },
    commonData: {
      heightCalculation: { heroBannerHeightCalculated },
    },
    filterAppliedCounter,
    sortType,
  } = useSelector((state) => ({
    storeData: state.storeReducer.store,
    catalogData: state.catalogReducer,
    commonData: state.commonReducer,
    filterAppliedCounter: state.catalogReducer.filterAppliedCounter,
    sortType: state.catalogReducer.sortType,
  }));

  const { store_info, theme } = useSSRSelector((state) => state.storeReducer.store);

  const categoryListRef = useRef(null);

  const iteratableCategories = useMemo(
    () => [...productTags, ...categories],
    [productTags?.length, categories?.length]
  );

  const shouldRenderSortAndFilter =
    (sortType || filterAppliedCounter || router?.query?.cid) &&
    props?.sortAndFiltersEnabled;

  const commonProps = {
    onItemClick,
    showATCBtn: props.showATCBtn,
    iteratableCategories,
    categorySelected,
  };

  useTriggerInitialFilterOptions(router?.query?.cid === undefined);

  useEffect(() => {
    const filteredIndexFunc = (id = null) => {
      if (id)
        return [...productTags, ...categories].findIndex((el) => el.id === Number(id));
    };

    if (iteratableCategories.length > 0) {
      if (router?.query?.cid >= 0) {
        setCategorySelected(router?.query?.cid);
        setActiveCategoryIndex(filteredIndexFunc(router?.query?.cid));
      } else {
        setCategorySelected(iteratableCategories?.[0]?.id);
        setActiveCategoryIndex(0);
      }
    }
  }, [router?.query?.cid, iteratableCategories]);

  const onSelectedCategoryChange = async (id) => {
    window.scrollTo({ top: 0 });
    categoryListRef.current.scrollTo({ top: 0 });
    // categoryListRef.current.scrollIntoView({ behavior: 'instant' });
    if (id == categorySelected) return;

    const util = await import('@/utils/routes');
    const { getRoute, PATH } = util;

    const route = getRoute(
      `${props.shopPageBoolean ? PATH.SHOP : PATH.PRODUCT}?cid=${id}`,
      store_info?.domain
    );

    setTimeout(() => router.push(route, undefined, { shallow: true }), 900);
  };

  function handleShowFilterDrawer(e) {
    e?.stopPropagation();
    setShowFilterDrawer(!showFilterDrawer);
  }

  function returnMiddleBodyWidget() {
    return shouldRenderSortAndFilter ? (
      <SortAndFilteredItems />
    ) : (
      <SelectedCategoryItems activeCategoryIndex={activeCategoryIndex} {...commonProps} />
    );
  }

  return (
    conditionalForMiddlebodyRender(
      heroBannerHeightCalculated,
      theme?.theme_id || theme?.theme_id === 0,
      router?.route?.includes('shop')
    ) && (
      <div className={`flex flex-row ${showFilterDrawer ? '' : 'justify-center'}`}>
        {props.sortAndFiltersEnabled && (
          <FilterDrawerMiddleBody5
            showFilterDrawer={showFilterDrawer}
            handleShowFilterDrawer={handleShowFilterDrawer}
            filterDrawerWrapperRef={filterDrawerWrapperRef}
            hideCollectionFilter={true}
            hideCategoryFilter={true}
            hideTags={true}
          />
        )}
        <AdWidgetContainer
          trasnlationDist={translationDistanceForAnimation.current}
          ref={middleBodyContainerRef}
          showFilterDrawer={showFilterDrawer}
        >
          <DesktopDsAds
            mr={'mr20px'}
            pt={'pt70px'}
            mb={'mb180px'}
            ml={'ml20px'}
            id={'DesktopDsAds-1'}
          />
          <Container>
            <Heading>Categories</Heading>
            {props.sortAndFiltersEnabled && (
              <SortingAndFiltersTab
                middleBodyRef={middleBodyContainerRef}
                showFilterDrawer={showFilterDrawer}
                filterDrawerWrapperRef={filterDrawerWrapperRef}
                handleShowFilterDrawer={handleShowFilterDrawer}
              />
            )}
            <CategorySliderComp
              categories={categories}
              activeIndex={activeCategoryIndex}
              onItemSelection={onSelectedCategoryChange}
            />
            {/** This component is used to mark an anchor to scroll to the top of rendered lists easily */}
            <div ref={categoryListRef} />
            {returnMiddleBodyWidget()}
          </Container>
          <DesktopDsAds
            mr={'mr20px'}
            pt={'pt70px'}
            mb={'mb180px'}
            ml={'ml20px'}
            id={'DesktopDsAds-2'}
          />
        </AdWidgetContainer>
      </div>
    )
  );
};

export default HybridFiltered;
