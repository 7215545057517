import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import {
  FilterDrawerMobileCta,
  FilterDrawerMobileCtaApply,
  FilterDrawerMobileCtaContainer,
  FilterDrawerWrapper,
} from '../Hybrid.styles';
import { useDispatch, useSelector } from 'react-redux';
import FilterDrawer from '../../MiddleBody7/Components/FilterDrawer/FilterDrawer';
import { IS_MOBILE } from '@/utils/checkClientDeviceType';
import { hideFiltersCategoryAndCollection } from '@/redux/actions';

function FilterDrawerMiddleBody5(props) {
  const dispatch = useDispatch();

  const { showFilterDrawer, handleShowFilterDrawer } = props;

  const { headerHeight, headerRef } = useSelector((state) => ({
    headerHeight: state.commonReducer.headerHeight,
    headerRef: state.commonReducer.headerRef,
  }));

  useEffect(() => {
    dispatch(hideFiltersCategoryAndCollection(true));
    return () => {
      dispatch(hideFiltersCategoryAndCollection(false));
    };
  }, []);

  useEffect(() => {
    if (IS_MOBILE && showFilterDrawer) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };
  }, [showFilterDrawer]);

  useEffect(() => {
    if (IS_MOBILE && headerRef.current) {
      showFilterDrawer
        ? (headerRef.current.style.display = 'none')
        : (headerRef.current.style.display = '');
    }
  }, [showFilterDrawer]);

  return (
    <AnimatePresence>
      {showFilterDrawer && (
        <motion.div
          key="filterDrawer"
          initial={{ opacity: 0, x: IS_MOBILE ? 0 : -300, zIndex: -1 }}
          transition={IS_MOBILE ? { duration: 0.5 } : { duration: 0.5 }}
          animate={{ opacity: 1, x: 0, zIndex: IS_MOBILE ? 12 : 0 }}
          exit={{ opacity: 0, x: IS_MOBILE ? 0 : -300, zIndex: -1 }}
          style={{
            height: '100vh',
            display: 'flex',
            top: IS_MOBILE ? 0 : headerHeight,
            position: IS_MOBILE ? 'fixed' : 'sticky',
            background: 'white',
          }}
        >
          <FilterDrawerWrapper
            showFilterDrawer={showFilterDrawer}
            ref={props.filterDrawerWrapperRef}
          >
            <FilterDrawer
              handleShowFilterDrawer={handleShowFilterDrawer}
              hideCollectionFilter={props.hideCollectionFilter}
              hideCategoryFilter={props.hideCategoryFilter}
              hideTags={props.hideTags}
            />
            {IS_MOBILE && props.showFilterDrawer && (
              <FilterDrawerMobileCtaContainer onClick={handleShowFilterDrawer}>
                <FilterDrawerMobileCta>Close</FilterDrawerMobileCta>
                <FilterDrawerMobileCtaApply>Apply</FilterDrawerMobileCtaApply>
              </FilterDrawerMobileCtaContainer>
            )}
          </FilterDrawerWrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default FilterDrawerMiddleBody5;
